.container {
  padding: 0 !important;
}

.cardcontainer {
  background-color: #2f2f2f;
  border-radius: 12px;
  flex-wrap: nowrap !important;
  width: 100%;
  min-height: 160px;
}

.leftline {
  width: 4px;
  background-color: #47ccd6;
  height: 133px;
  border-radius: 12px;
}
.leftlineOrange {
  width: 0.277rem;
  background-color: #ee6f47;
  height: 9.305rem;
  border-radius: 12px;
}
.categoryOrange {
  font-size: 12px;
  font-weight: 500;
  color: #ee6f47;
  font-family: "Articulat CF Medium";
}
.customConnectorleftline {
  width: 0.277rem;
  background-color: #eb6f95;
  height: 9.305rem;
  border-radius: 12px;
}
.customConnectorColor {
  font-size: 12px;
  font-weight: 500;
  color: #eb6f95;
  font-family: "Articulat CF Medium";
}

.connectorlogoarea {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4b4b4b;
  border-radius: 4px;
}

.connectornamearea {
  font-size: 16px;
  font-weight: bold;
  color: #fafafa;
}

.connectorname {
  display: flex;
  align-items: center;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  font-weight: bold;
}

.title {
  font-size: 10px;
  font-weight: 500;
  color: #777777;
}

.connectorcategory {
  font-size: 12px;
  font-weight: 500;
  color: #ececec;
}

.connectorcategoryarea {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-family: "Articulat CF Medium";
  font-weight: bold;
}

.category {
  font-size: 12px;
  color: #47ccd6;
  font-family: "Articulat CF Medium";
}

.description {
  font-family: "Articulat CF Medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  color: #f6f6f6;
}
