.switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.switch {
  position: relative;
  width: 2.3rem;
  height: 1.3rem;
  background-color: #f6f6f6;
  border: 0.063rem solid #f6f6f6;
  border-radius: 50px;
  transition: background-color 0.3s;
}

.switch.on {
  background-color: #47ccd629;
  border: 0.063rem solid #47ccd6;
}

.switch.off {
  background-color: #47ccd629;
  border: 0.063rem solid #47ccd6;
}

.slider {
  position: absolute;
  top: 50%;
  left: 0.188rem;
  width: 0.813rem;
  height: 0.813rem;
  background-color: #f6f6f6;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.slider-on {
  transform: translate(0.938rem, -50%);
  background-color: #47ccd6;
}

.slider-off {
  transform: translate(0, -50%);
  background-color: #47ccd6;
}

/* Text Styles */
.text-on {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 1rem;
}
.text-off {
  font-size: 1rem;
  font-weight: 500;
  margin-left: 1rem;
}
