@mixin defaultAuthenticatedbackgroundPageStyle {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background-color: #171717;
}

.onBoardingContentStyle {
  background-color: #171717;
  height: 100%;
}
.onBoardingPageMainStyle {
  @include defaultAuthenticatedbackgroundPageStyle;

  .onBoardingLandingPageStyle {
    text-align: center;

    .onBoardingHashTagStyle {
      margin-top: 174px;
    }
    .onBoardingTitleStyle {
      margin-top: 60px;
    }
    .onBoardingSubTitleStyle {
      margin-top: 16px;
    }
    .onBoardingButtonStyle {
      margin-top: 56px;
    }
  }
}

.onBoardingPageMainStylePolka {
  background-color: #171717;
  background-size: 10px 10px;
}

.onBoardingConnectorCardsStyle {
  padding: 100px 0px 65px 0px;
}

.workspaceCardsStyle {
  margin: 24px 0px 0px 0px;
  height: 40%;
  overflow: auto;
}

.workSpaceGridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Create 4 equal columns */
  gap: 24px; /* Add gap between cards */

  /* Responsive adjustments */
  @media (max-width: 1000px) {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Change to 3 columns on screens up to 768px */
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Change to 2 columns on screens up to 480px */
  }

  @media (max-width: 445px) {
    grid-template-columns: 1fr; /* Change to 1 column on screens up to 320px */
  }
}

.onBoardingWokspaceTitle {
  padding: 32px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.onBoardingWokspaceTitleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 204px;
  background-color: #41bac3;
  position: relative;
  cursor: pointer;
  border-radius: 12px;

  p {
    font-size: 16px;
    color: #fafafa;
    margin: 0 auto;
  }
}

.onBoardingPipeLineTitle {
  padding: 32px 0px 0px 0px;
}

.onBoardingPipeLineSubTitle {
  padding: 32px 0px 0px 0px;
  align-items: center;
  display: flex;

  .onBoardingPipeLineSearchBoxStyle {
    display: flex;
    justify-content: flex-end;
  }
}

.onBoardingManufactureCardStyle {
  margin: 10px 0px 0px 0px;
  .onBoardingCardsFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .onBoardingCardMultiplierStyle {
      margin: 24px 0px 0px 15px;
      flex: 0 0 auto;
    }
  }
}
.onBoardingPipeLineDescription {
  padding: 8px 0px 0px 0px;
}

.onBoardingDeviceList {
  justify-content: center;
  align-items: center;
  display: flex;
}

.onBoardingDeviceListTitle {
  padding: 8px 16px 0px 16px;
}

.onBoardingDeviceListTitleText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: white;
}

.onBoardingDeviceListDescription {
  padding: 8px 0px 0px 16px;
}
.deviceListConfigSkeleton {
  height: 425px;
  padding: 24px 0px 0px 0px;
  margin: 0px 0px 14px 0px;
  background: #313131;
  overflow-y: auto;
  .onBoardingManufactureCardStyle {
    margin: 10px 0px 10px 0px;
    .onBoardingCardsFlex {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .onBoardingCardMultiplierStyle {
        margin: 24px 0px 0px 15px;
        flex: 0 0 auto;
      }
    }
  }
}

.deviceListPageTitleRow {
  padding: 32px 0px 0px 0px;

  .deviceListPageSubTitle {
    margin: 12px 0px 0px 0px;
  }
}

.deviceListsMainRow {
  padding: 24px 0px 0px 0px;
}
.deviceListBottomStyling {
  padding: 0px 0px 24px 0px;
}

.onBoardingFooterMainRow {
  bottom: 20px;
  right: 30px;
  background-color: #171717;

  .onBoardingFooterSkeleton {
    height: 80px;
    display: flex;

    .onBoardingFooterStyle {
      display: flex;
      align-items: center;
      justify-content: center;
      .onBoardingFooterProgressBar {
        margin: 10px 0px 0px 0px;
      }
      .onBoardingFooterButtons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

/* New Connector Card Styling*/
.connectorCardMainContainer {
  background-color: #272727;
  width: 232px;
  display: flex;

  .connectorCardMainContainerDashed {
    box-sizing: border-box;
    height: 120px;
    border: 2px dashed #909090;
  }

  .connectorCardCommonIconStyle {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .connectorCardColoredContainer {
    width: 8px;
  }

  .connectorCardDetailsContainer {
    width: 226px;
    padding: 8px;
    overflow: hidden;

    .connectorCardDetailsRow1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;

      .connectorCardDetailsRow1Styling {
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 0;

        .connectorCardDetailsIconBox {
          display: flex;
          align-items: center;
          height: 16px;
          width: 16px;
        }

        .connectorCardDetailsTitleText {
          font-size: 14px;
          color: #fafafa;
          margin-left: 8px;
        }
      }

      .connectorCardDetailsProgressBox {
        display: flex;
        align-items: center;
        height: 16px;
        width: 16px;
      }
    }

    .connectorCardDetailsRow2 {
      height: 16px;
      margin-top: 8px;
      font-size: 12px;
      color: #fafafa;
      display: flex;
      align-items: center;
    }

    .connectorCardDetailsRow3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      margin-top: 8px;
      color: #fafafa;
    }

    .connectorCardDetailsRow4 {
      height: 16px;
      margin-top: 8px;
      font-size: 12px;
      color: #fafafa;
      display: flex;
      align-items: center;
    }

    .connectorCardDetailsRow5 {
      margin-top: 8px;
      display: flex;
      justify-content: end;
      align-items: center;

      .connectorCardDetailsReConfigureIcon {
        display: flex;
        align-items: center;
        height: 16px;
        width: 16px;
      }
      .connectorCardDetailsReConfigureText {
        height: 16px;
        font-size: 12px;
        margin-left: 8px;
        color: #fafafa;
        display: flex;
        justify-content: end;
        align-items: center;
      }
    }
  }

  .connectorCardAddIconContainer {
    z-index: 1;
    display: flex;
    align-items: center;
    margin-right: -10px;
  }

  .connectorCardAddIcon {
    height: 18px;
    width: 18px;
  }
}

.componentCardSkeletonStyle {
  margin: 20px 0px 0px 0px;
  .componentCardSkeletonMargin {
    align-items: center;
  }
}

.configureCardContainer {
  padding: 16px 16px 16px 16px;
}

.configureLineRow {
  padding: 16px 0px 16px 0px;
}

.configureInputDescription {
  padding-top: 8px;
}

.noConfigurationMessageContainer {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noConfigurationMessage {
  color: white;
  font-size: 20px;
}

.setupCardPrerequisites {
  padding-top: 16px;
}

.setuCardInstructions {
  padding-top: 8px;
}

.componentCardTextStyle {
  padding: 10px 0px 0px 0px;
  text-align: start;
}

.connectorCardDashedSkeleton {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 20px 0px 0px 0px;
}
.connectorCardDashedHeight {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 0px 0px;
}

.createWorkSpaceModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 3001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createWorkSpaceModal::backdrop {
  backdrop-filter: blur(5px);
}

.createWorkSpaceMainContainer {
  display: flex;
  flex-direction: column;
  width: 326px;
  height: 532px;
  position: absolute;
  top: 15%; /* Position the container below the button */
  right: 10px; /* Align the container to the right */
  z-index: 1;

  border: 1px solid rgba(250, 250, 250, 0.24);
  background: rgba(39, 39, 39, 0.7);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(8px);

  .createWorkSpaceContainerTitle {
    display: flex;
    height: 56px;
    padding: 16px;
    background-color: #171717;

    p {
      color: #fafafa;
      font-size: 16px;
      margin-right: 42px;
      width: 225px;
    }

    div {
      height: 24px;
      width: 24px;
      cursor: pointer;
    }
  }

  .createWorkSpaceContainerFormArea {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  .createWorkSpaceContainerFormName {
    width: 100%;
    height: 48px;
    padding: 16px;
    margin-bottom: 16px;
    border: 2px solid #fafafa;
    font-size: 14px;
    color: white;
    background-color: inherit;
    outline: none;
  }

  .createWorkSpaceContainerFormName::placeholder {
    color: white;
  }

  .createWorkSpaceContainerFormDescription {
    width: 100%;
    height: 116px;
    border: 2px solid #fafafa;
    background-color: inherit;
    padding: 16px;
  }

  .createWorkSpaceContainerFormDescriptionInput {
    font-size: 14px;
    color: white;
    border: none;
    outline: none;
    height: 68px;
    width: 100%;
    background-color: inherit;
    resize: none; /* Disable textarea resizing */
  }

  .createWorkSpaceContainerFormDescriptionInput::placeholder {
    color: white;
    display: flex;
    justify-content: start;
    align-items: start;
    font-size: 14px;
  }

  .createWorkSpaceCharacterCount {
    display: flex;
    justify-content: end;
    color: white;
    font-size: 12px;
    margin: 0px 0px 12px 0px;
  }

  .createWorkSpaceContainerFormIconTitle {
    font-size: 16px;
    color: #fafafa;
    margin: 16px 0px;
  }

  .createWorkSpaceContainerFormIcons {
    display: grid;
    grid-template-columns: repeat(7, 32px);
    grid-auto-rows: 32px;
    gap: 12px;

    .createWorkSpaceIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .createWorkSpaceIconStyle {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .createWorkSpaceSpinnerContainer {
    display: flex;
    justify-content: center;
    margin: 32px 0;
  }

  .createWorkSpaceSpinnerStyle {
    width: 50px;
    height: 50px;
  }

  .createWorkSpaceFormSubmitContainer {
    margin: 16px 0px 0px 0px;
  }

  .createWorkSpaceFormSubmitButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: #41bac3;
  }

  .createWorkSpaceFormCancelContainer {
    margin: 16px 0px 24px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
  }

  .createWorkSpaceFormCancelButton {
    width: 204px;
    height: 48px;
    border: none;
    background-color: inherit;
    font-size: 14px;
    color: #fafafa;
  }
}
